/* globals window */
import React, { useEffect, useState } from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'firebase/app'
import 'firebase/auth'
import initFirebase from 'utils/auth/initFirebase'
import axios from "utils/axios"
import Router from 'next/router';
// import useUser from 'utils/useUser'
import MyStorage from 'utils/mystorage'

// Init the Firebase app.
initFirebase()

const FirebaseAuth = () => {
  // Do not SSR FirebaseUI, because it is not supported.
  // https://github.com/firebase/firebaseui-web/issues/213
  const [renderAuth, setRenderAuth] = useState(false)
  
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setRenderAuth(true)
    }
  }, [])

  // const { User, mutateUser } = useUser({
  //   redirectTo: "/",
  //   redirectIfFound: false,
  //   // delay: 1500 // zbyt szybko redirect jest czasem i firebase sie gubi
  // });

  const firebaseAuthConfig = {
    callbacks: {
      signInSuccessWithAuthResult: function(authResult, redirectUrl) {
        try {
          var myCache = new MyStorage()
          var ref = myCache.get('ref')
          const verification = firebase.auth().currentUser.getIdToken().then(async (token) => {
              var verification = await axios.post("/api/login", {
                token: token,
                ref: ref
              }, { 
                  withCredentials: true,
                  headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
              })
              return verification
            })
            .then(async (verification) => { 
              myCache.del('user')
              myCache.set('user', verification.data)
              // await mutateUser(verification)
            }).then((redirect) => {
                Router.push('/')
            })
            return false
        } catch (error) {}
        }
    },
    // signInFlow: 'popup',
    signInSuccessUrl: '/',
    // Auth providers
    // https://github.com/firebase/firebaseui-web#configure-oauth-providers
    signInOptions: [
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        requireDisplayName: false,
        recaptchaParameters: {
          type: 'image', // 'audio'
          size: 'invisible', // 'normal', 'invisible' or 'compact'
          badge: 'bottomright' //' bottomright' or 'inline' applies to invisible.
        }
      },
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    credentialHelper: 'none',
    tosUrl: '/terms-of-service',
    privacyPolicyUrl: '/privacy-policy'  
  }

  const renderFirebase = () => {
    try {
      return (
        <StyledFirebaseAuth
          uiConfig={firebaseAuthConfig}
          firebaseAuth={firebase.auth()}
        />
      )
    } catch (error) {
    }
  }


  return (
    <div style={{marginLeft: "-20px"}}>
      {renderAuth ? renderFirebase() : null}
    </div>
  )
}

export default FirebaseAuth
